/* Estilos generales */
.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  padding: 20px;
}

h1 {
  color: #007bff;
}

.add-task-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.add-task-btn:hover {
  background-color: #0056b3;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  margin-right: 10px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filter-buttons button:hover {
  background-color: #0056b3;
}

.task-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.day-section {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.task-item.completed .task-name {
  text-decoration: line-through;
  color: #888;
}

.task-actions {
  display: flex;
  gap: 10px;
}

.task-actions button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.task-actions button:hover {
  background-color: #c82333;
}

/* Estilo vertical para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .task-list {
    grid-template-columns: 1fr;
  }
}

.task-form {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.vertical-form label {
  display: block;
  margin-bottom: 10px;
}

.vertical-form input, 
.vertical-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
}

.submit-btn {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

input, select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

input[type="date"], input[type="time"] {
  max-width: 200px;
}